import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Contact from "./Contact"

import "../assets/css/index.css"

import { SkipNavLink } from "@reach/skip-nav"
import "@reach/skip-nav/styles.css" //this will show/hide the link on focus

const Layout = ({ children }) => {
  return (
    <>
      <SkipNavLink />
      <Navbar />
      {children}
      <Contact />
      <Footer />
    </>
  )
}

export default Layout
