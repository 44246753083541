import React from "react"
import Insta from "../assets/icons/Instagram.svg"
import Linkedin from "../assets/icons/Linkedin.svg"

import Form from "./Form"

const Contact = () => {
  return (
    <section className="flex justify-center bg-whisper">
      <div
        id="contact"
        data-sal="zoom-in"
        data-sal-delay="150"
        data-sal-duration="500"
        data-sal-easing="easeInQuint"
        className="w-[800px]"
      >
        <div
          className="bg-gainsboro 
            rounded-md
            py-8 px-10 m-12
            flex flex-col justify-center
            drop-shadow"
        >
          <article
            className="flex flex-col align-middle justify-center 
          m-auto text-center text-tomato"
          >
            <h3 className="leading-5 font-semibold text-3xl mb-5 animate-bounce">
              Reach out to me!
            </h3>
            <p>Interested in a collaboration? Hit me up below!</p>
            <div className="flex align-middle justify-center m-5">
              <a
                className="mr-12"
                href="https://www.instagram.com/samwelloo/"
                rel="noreferrer"
                target="_blank"
              >
                <Insta />
              </a>
              <a
                href="https://www.linkedin.com/in/samuel-leongmk/"
                rel="noreferrer"
                target="_blank"
              >
                <Linkedin />
              </a>
            </div>
          </article>
          <Form />
        </div>
      </div>
    </section>
  )
}

export default Contact
