import React, { useState } from "react"
import { MdMenu } from "react-icons/md"
import {
  FaHome,
  FaAddressCard,
  FaArchive,
  FaEnvelopeOpenText,
} from "react-icons/fa"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const NAV = {
  OVERLAY:
    "h-0 w-full fixed z-20 top-0 bg-verydarkgrey overflow-x-hidden transition-height ease-in-out delay-75",
  OPEN: "h-full w-full fixed z-20 top-0 bg-verydarkgrey overflow-x-hidden transition-height ease-in-out delay-75",
  LINKS:
    "h-0 overflow-hidden flex flex-dir flex-col transition-height ease-in-out delay-75 md:h-auto md:height-auto;",
  SHOW_LINKS:
    "h-80 overflow-hidden flex flex-dir flex-col transition-height ease-in-out delay-75",
}

const Navbar = () => {
  const titles = ["Home", "info", "projects", "contact"]
  const links = ["#hero", "#info", "#projects", "#contact"]
  const icons = [FaHome, FaAddressCard, FaArchive, FaEnvelopeOpenText]
  const [show, setShow] = useState(false)

  return (
    <div className={show ? NAV.OPEN : NAV.OVERLAY}>
      <nav
        className="fixed flex z-10 align-middle justify-center
        md:top-1/2 md:translate-y-[-50%] md:right-0"
      >
        <div className="w-screen max-w-[1120px] md:w-auto ">
          <div
            className="h-24 flex flex-row-reverse align-middle py-7
            md:hidden"
          >
            <button
              className="text-whisper bg-seagreen py-1.5 px-3 mr-12
                rounded-md text-2xl drop-shadow-md
                hover:bg-oxley hover:drop-shadow-lg
                transition-height ease-in-out delay-75"
              onClick={() => setShow(!show)}
            >
              <MdMenu className="text-whisper" />
            </button>
          </div>
          <div className={show ? NAV.SHOW_LINKS : NAV.LINKS}>
            {titles.map((title, idx) => {
              const Icon = icons[idx]
              const link = links[idx]
              return (
                <AnchorLink
                  key={idx}
                  title={title}
                  to={"/" + link}
                  className="group block text-center text-2xl capitalize text-dimgray px-0 py-2 
                  transition-height ease-in-out delay-75
                  md:flex md:flex-row-reverse md:ml-auto"
                  onAnchorLinkClick={() => setShow(false)}
                >
                  <Icon className="hidden md:flex md:h-8 md:w-8 md:mx-2 md:hover:scale-125" />
                  <span
                    id="text"
                    className="md:max-w-0
                  md:m-auto md:overflow-hidden md:group-hover:max-w-xs duration-300 ease-in-out"
                  >
                    {title}
                  </span>
                </AnchorLink>
              )
            })}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
