import React from "react"

const Footer = () => {
  return (
    <div className="bg-whisper">
      <footer
        className=" text-base text-tomato text-center
        border-t border-tomato 
        flex justify-center m-auto p-5 w-3/4 h-auto"
      >
        <p>
          Designed and developed with ❤ by Samuel Leong. &copy;{" "}
          {new Date().getFullYear()} All rights reserved
        </p>
      </footer>
    </div>
  )
}

export default Footer
