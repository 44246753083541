import React from "react"

const Form = () => {
  const inputFields = ["name", "email"]
  const types = ["text", "email"]
  const placeHolder = ["John Doe", "JohnDoe@email.com"]

  return (
    <section className="w-full">
      <article>
        <form
          className="flex flex-col"
          acceptCharset="UTF-8"
          action="https://getform.io/f/5d534ff3-503c-4997-96e4-c5679deb7818"
          method="POST"
        >
          {inputFields.map((input, idx) => {
            return (
              <div key={idx} className="mb-4">
                <label className="block mb-2 capitalize" htmlFor={input}>
                  your {input}
                </label>
                <input
                  type={types[idx]}
                  name={input}
                  id={input}
                  className="outline-0 focus:ring-2 focus:ring-silver
                    block w-full px-4 py-2 rounded-md"
                  placeholder={placeHolder[idx]}
                  required
                />
              </div>
            )
          })}
          <div className="mb-4">
            <label className="block mb-2 capitalize" htmlFor="message">
              your message
            </label>
            <textarea
              name="message"
              id="message"
              className="h-28 min-h-[7rem]
                    outline-0 focus:ring-2 focus:ring-silver
                    block w-full px-4 py-2 rounded-md"
              placeholder="I would love to collab with you..."
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="text-whisper bg-seagreen py-1.5 px-3 
                  capitalize tracking-wide rounded-md 
                  hover:bg-oxley hover:drop-shadow-lg
                  transition ease-in-out delay-75 drop-shadow-md"
          >
            submit
          </button>
          <input type="text" name="_gotcha" className="hidden" />
        </form>
      </article>
    </section>
  )
}

export default Form
