import React from "react"
import slugify from "slugify"
import classNames from "classnames"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const defaultBtn = `text-whisper flex justify-center justify-center items-center
  py-3 px-5 capitalize tracking-wide rounded-md
   hover:drop-shadow-lg transition ease-in-out delay-75
  drop-shadow-md md:py-5 md:px-9 md:text-xl`

export const Button = props => {
  //const BUTTON = classNames("bg-darkbeaver hover:bg-beaver", defaultBtn)
  return (
    <div>
      <button className="relative px-8 py-5 overflow-hidden font-medium text-whisper bg-beaver rounded-lg group">
        <span className="absolute top-0 left-0 w-0 h-0 transition-all duration-200 border-t-2 border-viridian-300 group-hover:w-full ease"></span>
        <span className="absolute bottom-0 right-0 w-0 h-0 transition-all duration-200 border-b-2 border-viridian-300 group-hover:w-full ease"></span>
        <span className="absolute top-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-viridian-300 group-hover:h-full ease"></span>
        <span className="absolute bottom-0 left-0 w-full h-0 transition-all duration-300 delay-200 bg-viridian-300 group-hover:h-full ease"></span>
        <span className="absolute inset-0 w-full h-full duration-300 delay-300 bg-viridian border border-viridian opacity-0 group-hover:opacity-100"></span>
        <span className="absolute text-xl text-transparent group-hover:text-whisper delay-300 top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-center">
          {props.title}
        </span>
        <span className="relative transition-colors duration-300 delay-200 group-hover:text-transparent ease">
          {props.children}
        </span>
      </button>
    </div>
  )
}

export const ButtonSlug = props => {
  const slug = slugify(props.to, { remove: /[*+~.()'"!:@]/g, lower: true })
  const BUTTON = classNames(
    "bg-viridian transition-all duration-300 ease hover:scale-110 hover:bg-[#30B8C7]",
    defaultBtn
  )
  const page = "/" + slug
  return (
    <div>
      <AniLink cover direction="right" to={page} bg="#a33327">
        <button className={BUTTON}>{props.children}</button>
      </AniLink>
    </div>
  )
}
